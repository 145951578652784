import { get } from 'lodash';
import detectRoute, {
  POST_PAGE,
  CATEGORIES_PAGE,
  FEED_PAGE,
  SEARCH_RESULTS,
  POST_EDIT_PAGE,
} from '../services/detect-route';

import {
  getReferrer,
  getCurrentOrigin,
  getImageCount,
  getVideoCount,
  getQuoteCount,
  getEmojiCount,
  getMentionCount,
  getHashtagCount,
  getFileCount,
  getVoteType,
  getVoteFinalScore,
  POST_LIMITER_VALUES,
  hasCustomVideo,
  getGifCount,
  getReportType,
  getOriginFromAppSectionParams,
  getGuidelinesType,
  getTabNameByFeedType,
  getPreviousOrigin,
  getCreatePostOrigin,
  getCreatePostCTAOrigin,
  getBasicCreatePostCTAOrigin,
  feedTypeToTabName,
  getPollCount,
} from './bi-helpers';
import { actionsOpened } from '../actions/actions-opened';
import {
  buttonClicked,
  ButtonClickedAction,
  ButtonClickedBadges,
  ButtonClickedImageExpand,
  ButtonClickedLoginToComment,
  ButtonClickedMarkAsBestAnswer,
  ButtonClickedOnPost,
  ButtonClickedToggleSuggestions,
  ButtonClickedWhoLiked,
} from '../actions/button-clicked';
import { changePage } from '../actions/change-page';
import { NAVIGATE_WITHIN_FORUM } from '../../common/actions/navigate-within-forum';
import { createCommentSuccess } from '../actions/create-comment';
import { createPostRequest, CreatePostSuccess, createPostSuccess } from '../actions/create-post';
import { deletePostSuccess } from '../actions/delete-post';
import { fetchSearchSuccess } from '../actions/fetch-search';
import { incrementCommentLikeCountSuccess } from '../actions/increment-comment-like-count';
import { incrementPostLikeCountSuccess } from '../../common/actions/increment-post-like-count';
import { pageOpened } from '../actions/page-opened';
import { subscribeSuccess, SUBSCRIBE_SUCCESS } from '../actions/subscribe';
import { unsubscribeSuccess } from '../actions/unsubscribe';
import { voteCommentRequest } from '../actions/vote-comment';
import { updatePostRequest, UpdatePostSuccess, updatePostSuccess } from '../actions/update-post';
import { uploadEnded } from '../actions/upload-ended';
import { uploadStarted } from '../actions/upload-started';
import { reportPostSuccess } from '../actions/report-post';
import { reportCommentSuccess } from '../actions/report-comment';
import { getCategoryBySlug, getCategory } from '../../common/selectors/categories-selectors';
import { getPrevPath } from '../../common/store/location/location-selectors';
import { getPostBySlug, getPost } from '../selectors/post-selectors';
import { getComment } from '../selectors/comment-selectors';

import { setPostsPageSorting, setSearchPageSorting } from '../actions/set-sorting';
import { setPostTypeFilter } from '../actions/set-filter';
import {
  getPreviousMatches,
  getRouteParams,
  getCurrentMatch,
  getRouteQueryParams,
} from '../../common/router/router-selectors';
import { isOwner } from '../../common/store/current-user/current-user-selectors';
import { setHasReportedAppLoaded } from '../../common/store/app-loaded/app-loaded-actions';
import { TYPE_IMAGE } from '../../common/services/wix-media/media-types';
import { fileDownloadRequest, fileUploaded } from '../actions/file-upload-bi-actions';
import { POST_LIMIT_REACHED, COMMENT_LIMIT_REACHED } from '../components/messages/message-types';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { setSavedAction } from '../../common/store/saved-action/set-saved-action';
import { getIsPostingDisabled, getPostLimiter } from '../selectors/forum-data-selectors';
import {
  userEventsClickPublish,
  userEventsClickLike,
  userEventsReplyOrCommentIntent,
  userEventsShowEmptyState,
  userEventsClickProfileLink,
  userEventsHitUploadSizeLimit,
  userEventsClickCreatePost,
  userEventsClickCreatePostButton,
  userEventsPostCaptchaShown,
  userEventsPostCaptchaResolved,
  userEventsCommentCaptchaShown,
  userEventsCommentCaptchaResolved,
  userEventsGuidelinesShown,
  userEventsPostingDisabledShown,
  userEventsPostingDisabledClose,
  userEventsSearchFocused,
  userEventsSearchCleared,
  userEventsSearchSuggestionsLoaded,
  userEventsSearchSubmitted,
} from '../actions/user-events';
import { getIsMainPageEnabled } from '../selectors/app-settings-selectors';
import { getEntity, getTotalPages, getEntityCount } from '../selectors/pagination-selectors';
import { getCommentsPerPage, POSTS_PER_PAGE } from '../constants/pagination';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { LIKE_POST, LIKE_COMMENT } from '../constants/interactions';
import { FILTER_SHOW_ALL } from '../constants/filtering';
import { INVALID_APP_STATE_PAGE } from '../constants/page-names';
import { getCategoryPostType } from '@wix/communities-forum-client-commons/dist/src/services/category-utils';
import { getSearchResultsCurrentPage } from '../selectors/search-selectors';
import {
  appFullyLoaded,
  createPostIntent,
  followPostClick,
  followCategoryClick,
  paginationClick,
  searchResults,
  goToProfileClick,
  postDeleted,
  createPostClick,
  clickOnCategory,
  clickOnPost,
  editPostImageStartUploading,
  actionButtonActionClick,
  editPostImageFinishUploading,
  actionButtonLogInSignUp,
  categoriesClickOnCreateBadgeRolePaidPlan,
  forumImageExpand,
  cogwheelMenuOpened,
  whoLikedPostClick,
  markAsBestClick,
  toggleAutoSuggest,
  fileUploadSizeLimitReached,
  reportPost,
  postPageOpened,
  editPostPostEditingWindowOpened,
  mainPageOpened,
  postListOpened,
  searchPageSearchPageOpened,
  customErrorPageRendered,
  postPublished,
  likePublished,
  editingPostWindowPublishPostClick,
  actionButtonOpenActions,
  postSortInCategory,
  fileUploaded as fileUploadedBi,
  fileDownloaded,
  postLimitReached,
  emptyStateShown,
  likeClicked,
  attemptToPublishPost,
  clickToWriteCommentReply,
  postFilter,
  upvoteDownvoteClick,
  captchaOnPostPublish,
  clickOnShowHideGuidelines,
  shownGuidelines,
  forumTabClick,
  readOnlyMessageShown,
  clickOnSearchField,
  clickInSearchFieldX,
  searchTabDropListShowed,
  clickSeeAllResultsInSearchField,
} from '@wix/bi-logger-forums-uou/v2';
import { hideGuidelinesSuccess, showGuidelinesSuccess } from '../actions/guidelines';
import {
  CATEGORY_GUIDELINES,
  FORUM_GUIDELINES,
  getDisplayGuidelinesTypeFromStore,
} from '../selectors/guidelines-selectors';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { decodeQuery } from '../services/query-encoding';
import {
  FeedType,
  getFeedType,
  getTabsReferrer,
  getTabsOrigin,
  navigateToMainPageStart,
} from '../containers/header-navigation';
import { BiEventsExecutor } from './bi-types';
import { createActionMatcher } from './create-bi-action-matcher';
import { setRouterMatch } from '../../common/router/router-actions';
import { Comment } from '@wix/ambassador-forum-v1-comment/types';
import { RootState } from '../reducers/root-state';
import { SearchResultComment } from '../types';

export const uouBiEventsExecutor = ({
  action: outerAction,
  state,
  logger,
  biLocation,
  isSite,
  config,
  viewerSessionId,
}: BiEventsExecutor<AllActions, RootState>) => {
  createActionMatcher<AllActions>(state, logger)
    .match(
      [setHasReportedAppLoaded],
      () => {
        return isSite ? appFullyLoaded : undefined;
      },
      () => {
        return { vsi: viewerSessionId };
      },
    )
    .match(
      [userEventsClickCreatePostButton],
      () => createPostIntent,
      () => {
        return {
          origin: 'live',
        };
      },
    )
    .match(
      [subscribeSuccess],
      action => {
        if (action.payload.type === 'post') {
          return followPostClick;
        }
        if (action.payload.type === 'category') {
          return followCategoryClick;
        }
        return;
      },
      action => {
        if (action.payload.type === 'post') {
          return {
            flag: true,
            post_followed: action.payload._id,
            posts_format: getPost(state, action.payload._id).postType,
            origin: biLocation.get(SUBSCRIBE_SUCCESS) || getCurrentOrigin(state),
          };
        }

        const category = getCategory(state, action.payload._id);
        return {
          flag: true,
          type: 'follow',
          posts_format: getCategoryPostType(category),
          origin: getCurrentOrigin(state),
        };
      },
    )
    .match(
      [unsubscribeSuccess],
      action => {
        if (action.payload.type === 'post') {
          return followPostClick;
        }
        if (action.payload.type === 'category') {
          return followCategoryClick;
        }
        return;
      },
      action => {
        const origin = biLocation.get(SUBSCRIBE_SUCCESS) || getCurrentOrigin(state);
        if (action.payload.type === 'post') {
          return {
            flag: false,
            post_followed: action.payload._id,
            posts_format: getPost(state, action.payload._id).postType,
            origin,
          };
        }

        const category = getCategory(state, action.payload._id);
        return {
          flag: false,
          type: 'follow',
          posts_format: getCategoryPostType(category),
          origin: getCurrentOrigin(state),
        };
      },
    )
    .match(
      [changePage],
      action => {
        const buttonType = get(action.payload, 'meta.bi.buttonType');
        if (buttonType) {
          return paginationClick;
        }
      },
      action => {
        const buttonType = get(action.payload, 'meta.bi.buttonType');
        return {
          page_destination: action.payload.page,
          button_type: buttonType,
          origin: getCurrentOrigin(state),
        };
      },
    )
    .match(
      [fetchSearchSuccess],
      () => searchResults,
      action => {
        const isForumTabsEnabled = isExperimentEnabled(state, EXPERIMENT_FORUM_TABS);

        return {
          origin:
            (isForumTabsEnabled
              ? feedTypeToTabName(getTabsReferrer(state, 1))
              : getPreviousOrigin(state)) || getCurrentOrigin(state), // TODO: after release when searching 2nd time in search page it should show search page
          referral_info: 'direct',
          search_term: decodeQuery(decodeURIComponent(getRouteParams(state)?.query || '')),
          result_count_posts: action.payload.posts.length,
          result_count_members: action.payload.users.length,
        };
      },
    )
    .match(
      [userEventsClickProfileLink],
      () => goToProfileClick,
      action => {
        return {
          origin: getCurrentOrigin(state),
          is_my_profile: action.payload.isOwnProfile,
        };
      },
    )
    .match(
      [deletePostSuccess],
      () => postDeleted,
      action => {
        const categorySlug = getRouteParams(state).categorySlug;
        const category = categorySlug
          ? getCategoryBySlug(state, categorySlug)
          : getCategory(state, getPost(state, action.payload)?.categoryId);
        return {
          post_id: action.payload,
          category_posts_format: getCategoryPostType(category),
          is_admin: isOwner(state),
          origin: 'live',
        };
      },
    )
    .match(
      [userEventsClickCreatePost],
      () => createPostClick,
      action => {
        const isForumTabsEnabled = isExperimentEnabled(state, EXPERIMENT_FORUM_TABS);
        return {
          posts_format: action.payload,
          origin: isForumTabsEnabled ? getCreatePostCTAOrigin(state) : 'live',
          cta_origin: getBasicCreatePostCTAOrigin(state, true),
        };
      },
    )
    .match(
      [setRouterMatch],
      action => {
        const prevMatches = getPreviousMatches(state);
        const isInitialLoad = prevMatches.length === 0;

        if (isInitialLoad) {
          return;
        }
        const routeParams = action.payload.params;

        const { categorySlug, postSlug } = routeParams;
        const prevMatchParams = prevMatches && prevMatches.length > 0 && prevMatches[1].params;
        if (
          categorySlug &&
          postSlug &&
          prevMatchParams.categorySlug === categorySlug &&
          prevMatchParams.postSlug === postSlug
        ) {
          return;
        }

        const prevPath = getPrevPath(state);
        const path = action.payload.pathname;
        const route = detectRoute(path);

        if (route === FEED_PAGE) {
          const category = getCategoryBySlug(state, categorySlug);
          if (category) {
            return clickOnCategory;
          }
        }

        if (prevPath && route === POST_PAGE) {
          return clickOnPost;
        }
      },
      action => {
        const { categorySlug, postSlug, query } = action.payload.params;
        const prevPath = getPrevPath(state);
        const path = action.payload.pathname;
        const route = detectRoute(path);
        const biOrigin = biLocation.get(NAVIGATE_WITHIN_FORUM);
        const origin = biOrigin || detectRoute(prevPath);

        if (route === FEED_PAGE) {
          const category = getCategoryBySlug(state, categorySlug);
          if (category) {
            return {
              category_id: category._id,
              category_slug: category.slug,
              origin,
              main_display_type: getIsMainPageEnabled(state, config.style)
                ? 'list_of_categories'
                : 'list_of_posts',
            };
          }
        }
        const post = getPostBySlug(state, postSlug);

        if (post) {
          const search_term = detectRoute(prevPath) === SEARCH_RESULTS ? query : null;
          const isForumTabsEnabled = isExperimentEnabled(state, EXPERIMENT_FORUM_TABS);
          return {
            post_id: post._id,
            post_slug: postSlug,
            origin: isForumTabsEnabled
              ? biOrigin || feedTypeToTabName(getTabsReferrer(state, 1)) || origin
              : origin,
            search_term,
          };
        }
      },
    )
    .match(
      [uploadStarted],
      action => (action.payload.type === TYPE_IMAGE ? editPostImageStartUploading : undefined),
      () => {
        return {
          origin: getCurrentOrigin(state),
        };
      },
    )
    .match(
      [uploadEnded],
      action => (action.payload.type === TYPE_IMAGE ? editPostImageFinishUploading : undefined),
      () => {
        return {
          origin: getCurrentOrigin(state),
        };
      },
    )
    // !! Couldn't figure out the types needed to be able to match more
    // !! than 4 functions and their params. A workaround works by
    // !! giving a callback that creates the LogObject here.
    .match(
      [buttonClicked],
      action => {
        const origin = getCurrentOrigin(state);
        switch (action.payload.name) {
          case 'click_similar_post':
            const clickSimilarPostPayload = action.payload as ButtonClickedOnPost;
            return () =>
              clickOnPost({
                post_id: clickSimilarPostPayload._id,
                post_name: clickSimilarPostPayload.title,
                post_slug: clickSimilarPostPayload.slug,
                search_term: clickSimilarPostPayload.term,
                origin: clickSimilarPostPayload.origin,
                type: 'post',
              });
          case 'click_external_resource':
            const clickExternalResourcePayload = action.payload as ButtonClickedOnPost;
            return () =>
              clickOnPost({
                post_name: clickExternalResourcePayload.title,
                post_slug: clickExternalResourcePayload.slug,
                search_term: clickExternalResourcePayload.term,
                origin: clickExternalResourcePayload.origin,
                type: 'external_link',
              });
          case 'action_click':
            const actionClickPayload = action.payload as ButtonClickedAction;
            const category =
              actionClickPayload.categoryId && getCategory(state, actionClickPayload.categoryId);
            const isForumTabsEnabled = isExperimentEnabled(state, EXPERIMENT_FORUM_TABS);

            return () =>
              actionButtonActionClick({
                post_id: actionClickPayload.postId,
                category_posts_format: category ? getCategoryPostType(category) : '',
                origin: isForumTabsEnabled
                  ? feedTypeToTabName(getTabsOrigin(state)) || origin
                  : origin,
                type: actionClickPayload.type,
                action: actionClickPayload.action,
              });
          case 'log_in_to_comment':
            const logInToCommentPayload = action.payload as ButtonClickedLoginToComment;
            return () =>
              actionButtonLogInSignUp({
                type: logInToCommentPayload.type,
              });
          case 'create_badge_category_manager':
          case 'manage_badge_category_manager': {
            const categoryManagerPayload = action.payload as ButtonClickedBadges;
            const category = getCategory(state, categoryManagerPayload.id);
            return () =>
              categoriesClickOnCreateBadgeRolePaidPlan({
                button_name: categoryManagerPayload.name,
                category_id: categoryManagerPayload.id,
                posts_format: getCategoryPostType(category),
                origin: 'categoriesManager',
              });
          }
          case 'img_expand': {
            const imgExpandPayload = action.payload as ButtonClickedImageExpand;
            const { params } = getCurrentMatch(state);
            const post = getPostBySlug(state, params.postSlug);

            return () =>
              forumImageExpand({
                type: imgExpandPayload.origin,
                post_id: get(post, '_id'),
                category_id: get(post, 'categoryId'),
              });
          }
          case 'categories_settings_cog': {
            return () => cogwheelMenuOpened({});
          }
          case 'who_liked': {
            const whoLikedPayload = action.payload as ButtonClickedWhoLiked;
            const { params } = getCurrentMatch(state);
            const post = getPostBySlug(state, params.postSlug);

            return () =>
              whoLikedPostClick({
                type: whoLikedPayload.type,
                origin: 'post_page',
                post_id: get(post, '_id'),
              });
          }
          case 'mark_as_best_answer':
            const markAsBestAnswerPayload = action.payload as ButtonClickedMarkAsBestAnswer;
            return () =>
              markAsBestClick({
                flag: markAsBestAnswerPayload.isMarked,
                origin: markAsBestAnswerPayload.origin,
                post_id: markAsBestAnswerPayload.postId,
                comment_id: markAsBestAnswerPayload.commentId,
              });
          case 'toggle_suggestions':
            const toggleSuggestionsPayload = action.payload as ButtonClickedToggleSuggestions;
            return () =>
              toggleAutoSuggest({
                flag: toggleSuggestionsPayload.isEnabled,
                category_id: toggleSuggestionsPayload.categoryId,
              });
          default:
            throw new Error();
        }
      },
      () => {
        return {};
      },
    )
    .match(
      [userEventsHitUploadSizeLimit],
      () => fileUploadSizeLimitReached,
      action => {
        return {
          file_size: action.payload.fileSize,
          type: action.payload.type,
          file_type: action.payload.fileType,
          limit_value: action.payload.limit * 1000000,
        };
      },
    )
    .match(
      [reportPostSuccess],
      () => reportPost,
      action => {
        return {
          post_id: action.payload._id,
          type: 'post',
          origin: getCurrentOrigin(state),
          action: getReportType(action.payload.reportType),
        };
      },
    )
    .match(
      [reportCommentSuccess],
      () => reportPost,
      action => {
        return {
          post_id: action.payload._id,
          type: 'comment',
          origin: getCurrentOrigin(state),
          action: getReportType(action.payload.reportType),
        };
      },
    )
    .match(
      [pageOpened],
      action => {
        const isForumTabsEnabled = isExperimentEnabled(state, EXPERIMENT_FORUM_TABS);
        const feedType = getFeedType(state);
        const main_display_type =
          feedType === FeedType.MyPosts
            ? 'list_of_my_posts'
            : action.payload.isMainPageEnabled
            ? 'list_of_categories'
            : 'list_of_posts';
        const biOrigin = biLocation.get(NAVIGATE_WITHIN_FORUM);
        const origin = biOrigin || getReferrer(state) || getOriginFromAppSectionParams(state);

        switch (action.payload.page) {
          case POST_PAGE:
            const post = action.payload.post;
            let origin_page_nr: number;
            if (origin === SEARCH_RESULTS) {
              origin_page_nr = getSearchResultsCurrentPage(state, getIsMobile(state));
            } else if (origin === FEED_PAGE) {
              const entityPagination = getEntity(state, 'posts', post.categoryId) || {};
              const pages = entityPagination.entitiesByPage || [];
              origin_page_nr =
                // @ts-expect-error
                Object.keys(pages).find(index =>
                  pages[index].find((entityId: string) => entityId === post._id),
                ) as number;
            }
            return () =>
              postPageOpened({
                post_id: post._id,
                type: action.payload.page,
                post_slug: post.title,
                posts_format: post.postType,
                origin: isForumTabsEnabled
                  ? biOrigin ||
                    feedTypeToTabName(getTabsReferrer(state)) ||
                    action.payload.origin ||
                    origin
                  : action.payload.origin || origin,
                search_term:
                  getReferrer(state) === SEARCH_RESULTS
                    ? getRouteParams(state).query
                    : getRouteQueryParams(state)?.searchTerm || null,
                total_pages: getTotalPages(
                  state,
                  'comments',
                  post._id,
                  getCommentsPerPage(getIsMobile(state)),
                ) as number,
                origin_page_nr,
              });
          case POST_EDIT_PAGE:
            return () => editPostPostEditingWindowOpened({});
          case CATEGORIES_PAGE:
            return isSite
              ? () =>
                  mainPageOpened({
                    origin: isForumTabsEnabled
                      ? (feedTypeToTabName(getTabsReferrer(state)) as string) || origin
                      : origin,
                    main_display_type,
                    is_disabled_posting: getIsPostingDisabled(state) as boolean,
                  })
              : undefined;
          case FEED_PAGE:
            return isSite
              ? () =>
                  postListOpened({
                    origin,
                    category_id: action.payload.category._id,
                    category_slug: action.payload.category.slug,
                    main_display_type,
                    total_posts: getEntityCount(
                      state,
                      'posts',
                      action.payload.category._id,
                    ) as number,
                    total_pages: getTotalPages(
                      state,
                      'posts',
                      action.payload.category._id,
                      POSTS_PER_PAGE,
                    ) as number,
                  })
              : undefined;
          case SEARCH_RESULTS:
            return () => searchPageSearchPageOpened({});
          case INVALID_APP_STATE_PAGE:
            return () => customErrorPageRendered({});
          default:
            throw new Error();
        }
      },
      () => {
        return {};
      },
    )
    .match(
      [createPostSuccess],
      () => postPublished,
      action => createContentEntityData(action, state, 'post', false),
    )
    .match(
      [updatePostSuccess],
      () => postPublished,
      action => createContentEntityData(action, state, 'post', true),
    )
    .match(
      [createCommentSuccess],
      () => postPublished,
      action =>
        createContentEntityData(
          action,
          state,
          get(action, 'meta.isReply', false) ? 'reply' : 'comment',
          false,
        ),
    )
    .match(
      [incrementCommentLikeCountSuccess],
      () => likePublished,
      action => {
        return {
          post_id: action.payload._id,
          type: 'comment',
          flag: action.payload.isLiked,
          origin: getCurrentOrigin(state),
        };
      },
    )
    .match(
      [incrementPostLikeCountSuccess],
      () => likePublished,
      action => {
        const isForumTabsEnabled = isExperimentEnabled(state, EXPERIMENT_FORUM_TABS);

        return {
          post_id: action.payload._id,
          type: 'post',
          flag: action.payload.isLiked,
          origin: isForumTabsEnabled
            ? feedTypeToTabName(getTabsOrigin(state)) || getCurrentOrigin(state)
            : getCurrentOrigin(state),
        };
      },
    )
    .match(
      [updatePostRequest, createPostRequest],
      () => editingPostWindowPublishPostClick,
      () => {
        return {};
      },
    )
    .match(
      [actionsOpened],
      () => actionButtonOpenActions,
      action => {
        return {
          type: action.payload.type,
          origin: getCurrentOrigin(state),
        };
      },
    )
    .match(
      [setPostsPageSorting],
      action => {
        const dontReportBi = get(action, 'meta.dontReportBI');
        if (dontReportBi) {
          return;
        }
        return postSortInCategory;
      },
      action => {
        const categorySlug = getRouteParams(state).categorySlug;
        const category = getCategoryBySlug(state, categorySlug);

        return {
          sort_by: action.payload,
          category_id: category ? category._id : null,
          origin: 'posts_page',
          application_type: 'web',
        };
      },
    )
    .match(
      [setSearchPageSorting],
      action => postSortInCategory,
      action => {
        return {
          sort_by: action.payload,
          category_id: '',
          origin: 'search',
          application_type: 'web',
        };
      },
    )
    .match(
      [fileUploaded],
      () => fileUploadedBi,
      action => {
        return {
          application_type: 'web',
          file_id: action.payload.fileId,
          file_ext: action.payload.fileExtension,
          mime_type: action.payload.mimeType,
          file_size: action.payload.size,
          type: action.payload.origin,
          success: action.payload.isSuccessful,
          origin: 'live',
        };
      },
    )
    .match(
      [fileDownloadRequest],
      () => fileDownloaded,
      action => {
        return {
          application_type: 'web',
          file_id: action.payload.fileId,
          file_ext: action.payload.fileExtension,
          mime_type: action.payload.mimeType,
          file_size: action.payload.size,
          type: action.payload.origin,
          success: action.payload.isSuccessful,
        };
      },
    )
    .match(
      [showMessage],
      action => {
        if (
          action.payload.type === POST_LIMIT_REACHED ||
          action.payload.type === COMMENT_LIMIT_REACHED
        ) {
          return postLimitReached;
        }
      },
      action => {
        let type = '';
        if (action.payload.type === POST_LIMIT_REACHED) {
          type = 'post';
        } else if (action.payload.type === POST_LIMIT_REACHED) {
          type = action.payload.props.isReply ? 'comment' : 'reply';
        }
        return {
          application_type: 'web',
          post_limit:
            POST_LIMITER_VALUES[getPostLimiter(state) as keyof typeof POST_LIMITER_VALUES] || 10,
          type,
        };
      },
    )
    .match(
      [userEventsShowEmptyState],
      () => emptyStateShown,
      action => {
        return {
          empty_state_type: action.payload.type,
          category_id: action.payload.categoryId,
        };
      },
    )
    .match(
      [setSavedAction],
      action => {
        if (action.payload.action === LIKE_POST || action.payload.action === LIKE_COMMENT) {
          return likeClicked;
        }
      },
      action => {
        switch (action.payload.action) {
          case LIKE_POST:
            return {
              flag: true,
              post_id: action.payload.args[0],
              type: 'post',
              origin: getCurrentOrigin(state),
            };
          case LIKE_COMMENT:
            return {
              flag: true,
              post_id: action.payload.args[0],
              type: 'comment',
              origin: getCurrentOrigin(state),
            };
          default:
            return;
        }
      },
    )
    .match(
      [userEventsClickLike],
      () => likeClicked,
      action => {
        return {
          flag: action.payload.isLiked,
          post_id: action.payload._id,
          type: action.payload.type,
          origin: getCurrentOrigin(state),
        };
      },
    )
    .match(
      [userEventsClickPublish],
      () => attemptToPublishPost,
      action => {
        return {
          post_id: action.payload.postId,
          type: action.payload.type,
          symbol_count: action.payload.symbolCount,
          attempt_type: action.payload.isEditing ? 'edit' : 'new',
        };
      },
    )
    .match(
      [userEventsReplyOrCommentIntent],
      () => clickToWriteCommentReply,
      action => {
        return {
          type: action.payload.type,
          origin: action.payload.origin,
        };
      },
    )
    .match(
      [setPostTypeFilter],
      () => postFilter,
      action => {
        const categorySlug = getRouteParams(state).categorySlug;
        const category = getCategoryBySlug(state, categorySlug);
        return {
          filter_by: action.payload === FILTER_SHOW_ALL ? 'all' : action.payload,
          category_id: category ? category._id : null,
          origin: 'posts_page',
        };
      },
    )
    .match(
      [voteCommentRequest],
      () => upvoteDownvoteClick,
      action => {
        const { _id, postId } = action.payload;
        const comment = getComment(state, _id, postId);
        const voteType = getVoteType(action.payload, comment);
        return {
          action_type: voteType,
          post_id: _id,
          origin: getCurrentOrigin(state),
          final_state: getVoteFinalScore(voteType, comment),
          is_best_answer: comment.isBestAnswer,
        };
      },
    )
    .match(
      [userEventsPostCaptchaShown],
      () => captchaOnPostPublish,
      action => {
        return {
          captcha_step: 1,
          postType: 'post',
        };
      },
    )
    .match(
      [userEventsPostCaptchaResolved],
      () => captchaOnPostPublish,
      action => {
        const isResolved = action.payload;
        return {
          captcha_step: 2,
          postType: 'post',
          captcha_success: isResolved,
        };
      },
    )
    .match(
      [userEventsCommentCaptchaShown],
      () => captchaOnPostPublish,
      action => {
        return {
          captcha_step: 1,
          postType: 'comment',
        };
      },
    )
    .match(
      [userEventsCommentCaptchaResolved],
      () => captchaOnPostPublish,
      action => {
        const resolved = action.payload;
        return {
          captcha_step: 2,
          postType: 'comment',
          captcha_success: resolved,
        };
      },
    )
    .match(
      [hideGuidelinesSuccess],
      () => clickOnShowHideGuidelines,
      action => {
        const { type } = action.payload;
        return {
          flag: false,
          type: getGuidelinesType(type),
        };
      },
    )
    .match(
      [showGuidelinesSuccess],
      () => clickOnShowHideGuidelines,
      action => {
        const { type } = action.payload;
        return {
          flag: true,
          type: getGuidelinesType(type),
        };
      },
    )
    .match(
      [userEventsGuidelinesShown],
      () => shownGuidelines,
      action => {
        const { type, origin, categoryId } = action.payload;
        return {
          origin,
          category_id: categoryId,
          type: getGuidelinesType(type),
        };
      },
    )
    .match(
      [navigateToMainPageStart],
      () => forumTabClick,
      action => {
        return {
          origin: feedTypeToTabName(getTabsOrigin(state)),
          tab_name: getTabNameByFeedType(action.payload),
        };
      },
    )
    .match(
      [userEventsPostingDisabledClose],
      () => readOnlyMessageShown,
      () => {
        return {
          step: '1',
        };
      },
    )
    .match(
      [userEventsPostingDisabledShown],
      () => readOnlyMessageShown,
      action => {
        return {
          step: '2',
          option_value: action.payload ? 'make_active' : 'stay_read_only',
        };
      },
    )
    .match(
      [userEventsSearchFocused],
      () => clickOnSearchField,
      () => {
        return {
          origin: feedTypeToTabName(getTabsOrigin(state)),
        };
      },
    )
    .match(
      [userEventsSearchCleared],
      () => clickInSearchFieldX,
      () => {
        return {};
      },
    )
    .match(
      [userEventsSearchSuggestionsLoaded],
      () => searchTabDropListShowed,
      action => {
        return {
          origin: feedTypeToTabName(getTabsOrigin(state)),
          search_term: action.payload,
        };
      },
    )
    .match(
      [userEventsSearchSubmitted],
      () => clickSeeAllResultsInSearchField,
      action => {
        return {
          origin: feedTypeToTabName(getTabsOrigin(state)),
          search_term: action.payload.query,
          search_trigger: action.payload.location, // see_all_results / enter
        };
      },
    )
    .execute(outerAction);
};

function createContentEntityData(
  action: { payload: CreatePostSuccess | UpdatePostSuccess | SearchResultComment | undefined },
  state: RootState,
  type: string,
  isEdit: boolean,
) {
  if (action.payload === undefined) {
    return;
  }
  const entity = action.payload;
  const emojiCount = getEmojiCount(entity);
  const mentionCount = getMentionCount(entity);
  const imageCount = getImageCount(entity);
  const fileCount = getFileCount(entity);
  const quoteCount = getQuoteCount(entity);
  const videoCount = getVideoCount(entity);
  const hashtagCount = getHashtagCount(entity);
  const gifCount = getGifCount(entity);
  const pollCount = getPollCount(entity);

  const guidelinesTypeFromStore = getDisplayGuidelinesTypeFromStore(state, entity.categoryId) as
    | typeof CATEGORY_GUIDELINES
    | typeof FORUM_GUIDELINES;

  const isForumTabsEnabled = isExperimentEnabled(state, EXPERIMENT_FORUM_TABS);

  const guidelinesType = {
    [CATEGORY_GUIDELINES]: 'category',
    [FORUM_GUIDELINES]: 'forum',
  }[guidelinesTypeFromStore];

  hasCustomVideo(entity);
  return {
    application_type: 'web',
    /* legacy bi naming, here 'post_id' points to the id of individual post or comment
       and 'parent_post_id' identifies under which post the comment exists
    */
    parent_post_id: entity.postId || '',
    post_id: (entity as CreatePostSuccess)?._id || (entity as Comment).id || '',
    category_id: (entity as CreatePostSuccess).categoryId,
    posts_format:
      (entity as CreatePostSuccess).postType || (getPost(state, entity.postId).postType as string),
    type,
    is_edited: isEdit,
    has_emoji: emojiCount > 0,
    has_image: imageCount > 0,
    has_mention: mentionCount > 0,
    mention_count: mentionCount as number,
    image_count: imageCount as number,
    file_count: fileCount as number,
    has_video: videoCount > 0,
    is_custom_video: videoCount > 0 && (hasCustomVideo(entity) as boolean),
    has_quote: quoteCount > 0,
    has_hashtag: hashtagCount > 0,
    hashtag_count: hashtagCount as number,
    has_gif: gifCount > 0,
    has_poll: pollCount > 0,
    is_admin: isOwner(state) as boolean,
    origin: isForumTabsEnabled ? (getCreatePostOrigin(state, isEdit) as string) : 'live',
    cta_origin: getBasicCreatePostCTAOrigin(state) as string,
    initiated_by: get(action, 'meta.initiatedBy', null) as string,
    guidelines_type: guidelinesType,
    captcha_used: get(action, 'meta.isCaptchaUsed', false) as string,
  };
}

type AllActions =
  | ReturnType<typeof setHasReportedAppLoaded>
  | ReturnType<typeof userEventsClickCreatePostButton>
  | ReturnType<typeof subscribeSuccess>
  | ReturnType<typeof unsubscribeSuccess>
  | ReturnType<typeof changePage>
  | ReturnType<typeof fetchSearchSuccess>
  | ReturnType<typeof userEventsClickProfileLink>
  | ReturnType<typeof deletePostSuccess>
  | ReturnType<typeof userEventsClickCreatePost>
  | ReturnType<typeof setRouterMatch>
  | ReturnType<typeof uploadStarted>
  | ReturnType<typeof uploadEnded>
  | ReturnType<typeof userEventsHitUploadSizeLimit>
  | ReturnType<typeof reportPostSuccess>
  | ReturnType<typeof reportCommentSuccess>
  | ReturnType<typeof pageOpened>
  | ReturnType<typeof createPostSuccess>
  | ReturnType<typeof updatePostSuccess>
  | ReturnType<typeof createCommentSuccess>
  | ReturnType<typeof incrementCommentLikeCountSuccess>
  | ReturnType<typeof incrementPostLikeCountSuccess>
  | ReturnType<typeof updatePostRequest>
  | ReturnType<typeof createPostRequest>
  | ReturnType<typeof actionsOpened>
  | ReturnType<typeof setPostsPageSorting>
  | ReturnType<typeof setSearchPageSorting>
  | ReturnType<typeof fileUploaded>
  | ReturnType<typeof fileDownloadRequest>
  | ReturnType<typeof showMessage>
  | ReturnType<typeof userEventsShowEmptyState>
  | ReturnType<typeof setSavedAction>
  | ReturnType<typeof userEventsClickLike>
  | ReturnType<typeof userEventsClickPublish>
  | ReturnType<typeof userEventsReplyOrCommentIntent>
  | ReturnType<typeof setPostTypeFilter>
  | ReturnType<typeof voteCommentRequest>
  | ReturnType<typeof userEventsPostCaptchaShown>
  | ReturnType<typeof userEventsPostCaptchaResolved>
  | ReturnType<typeof userEventsCommentCaptchaShown>
  | ReturnType<typeof userEventsCommentCaptchaResolved>
  | ReturnType<typeof hideGuidelinesSuccess>
  | ReturnType<typeof showGuidelinesSuccess>
  | ReturnType<typeof userEventsGuidelinesShown>
  | ReturnType<typeof navigateToMainPageStart>
  | ReturnType<typeof userEventsPostingDisabledClose>
  | ReturnType<typeof userEventsPostingDisabledShown>
  | ReturnType<typeof userEventsSearchFocused>
  | ReturnType<typeof userEventsSearchCleared>
  | ReturnType<typeof userEventsSearchSuggestionsLoaded>
  | ReturnType<typeof userEventsSearchSubmitted>;
