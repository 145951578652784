import { FETCH_POSTS_SUCCESS, fetchPostsSuccess } from '../../actions/fetch-posts';
import {
  FETCH_FOOTER_POSTS_SUCCESS,
  fetchFooterPostsSuccess,
} from '../../actions/fetch-footer-posts';
import { PostActivityState } from './post-activity-state';
import { PAGE_OPENED, pageOpened } from '../../actions/page-opened';
import { POST_PAGE } from '../../services/detect-route';

const initialState: PostActivityState = {
  newPosts: [],
};

type Post = {
  _id: string;
  isNew?: boolean;
};

type Actions =
  | ReturnType<typeof fetchPostsSuccess>
  | ReturnType<typeof fetchFooterPostsSuccess>
  | ReturnType<typeof pageOpened>;

export function postActivity(state = initialState, action: Actions) {
  switch (action.type) {
    case FETCH_FOOTER_POSTS_SUCCESS:
    case FETCH_POSTS_SUCCESS:
      return {
        newPosts: Array.from(
          new Set([
            ...state.newPosts,
            ...action.payload.filter((p: Post) => p.isNew === true).map((p: Post) => p._id),
          ]),
        ),
      };
    case PAGE_OPENED:
      if (action.payload.page === POST_PAGE) {
        return {
          newPosts: state.newPosts.filter(id => id !== action.payload.post._id),
        };
      }
      return state;
    default:
      return state;
  }
}
