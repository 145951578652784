import { CommonRequest } from '../types';
import { RootState } from '../reducers/root-state';
import { getUrl } from '../../common/store/location/location-selectors';
import { resolveCommentDeepLink } from '../../api/comments/resolve-comment-deep-link.api';
import URI from '@wix/communities-forum-client-commons/dist/src/services/urijs';

export const resolveWixCommentsDeepLink = (commentId: string, postId?: string) => {
  return (_dispatch: any, getState: any, { request }: { request: CommonRequest }) => {
    const state: RootState = getState();
    const url = getUrl(state);

    return request.httpClient
      .request(resolveCommentDeepLink({ commentId, postId }))
      .then(({ data }) => {
        // @ts-expect-error // second param for URI is not mandatory
        // Persist query params
        const query = new URI(url).query();
        const queryString = query ? `?${query}` : '';

        return `${data}${queryString}`;
      });
  };
};
